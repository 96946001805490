import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {
    Message,
    Modal,
    Breadcrumb,
    Card,
    Grid,
    Form,
    Space,
    Button,
    Divider,
    Table,
    Pagination,
    Input,
    InputNumber,
    Menu,
} from "@arco-design/web-react";
import {IconSave, IconUndo, IconPlusCircle, IconEye, IconDelete, IconUpload, IconDownload} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useOutletContext} from "react-router-dom";
import {Map, GridLayer, TileLayer} from "leaflet";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import File from "../../../../../nowui-common/component/file";
import Loading from "../../../../../nowui-common/component/loading";

import ImageLocation from "./location.png";

let map;
let marker;

const CommunityIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const columnListRef = useRef([]);
    const [columnList, setColumnList] = useState([]);
    const [operationColumn, setOperationColumn] = useState({});
    const [isLoad, setIsLoad] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [processType, setProcessType] = useState(0);
    const [isImport, setIsImport] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectedIdList, setSelectedIdList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [action, setAction] = useState("");
    const [communityId, setCommunityId] = useState("");
    const [communityCount, setCommunityCount] = useState(0);
    const [communityList, setCommunityList] = useState([]);
    const [communityPageIndex, setCommunityPageIndex] = useState(1);
    const [communityPageSize, setCommunityPageSize] = useState(10);
    const [sortList, setSortList] = useState([]);
    const [isMarker, setIsMarker] = useState(false);
    const [areaId, setAreaId] = useState("");
    const [coordinateX, setCoordinateX] = useState(-1);
    const [coordinateY, setCoordinateY] = useState(-1);

    useEffect(() => {
        setTimeout(() => {
            map = new Map("map", {
                crs: L.CRS.Simple,
                center: [0, 0],
                minZoom: 0,
                maxZoom: 3,
                attributionControl: false,
                zoomControl: false,
            });

            const bounds = [
                [0, 0],
                [3584, 3584],
            ];

            map.fitBounds(bounds);

            const gaodeLayer = new TileLayer("http://localhost:1950/cube/{x}/{y}/{z}", {
                subdomains: "",
                tileSize: 256,
                // tileSize: 64,
                noWrap: true,
                bounds: bounds,
            });
            gaodeLayer.addTo(map);

            map.on("click", function (e) {
                var latlng = e.latlng;
                let coordinateX = parseInt(latlng.lng / 64);
                let coordinateY = parseInt(latlng.lat / 64);
                let markerX = coordinateX * 64 + 64 / 2;
                let markerY = coordinateY * 64 + 64 / 2 + 25;

                // handleLoadArea(coordinateX, coordinateY, markerX, markerY);
            });
        }, 300);

        return () => {
            controller.abort();

            map.remove();

            if (marker) {
                marker.remove();
            }
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/community/index");
        setBreadcrumbList(breadcrumbList);

        Util.changeSortList(sortList, setSortList, columnList);
    }, [localStorage.getItem("locale")]);

    const handleLoadArea = (coordinateX, coordinateY, markerX, markerY) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);
        setIsMarker(false);

        form.resetFields();

        Util.request(outletContext, {
            url: process.env.API_URL + "/cube/area/backend/website/v1/item",
            controller: controller,
            data: {
                coordinateX: coordinateX,
                coordinateY: coordinateY,
            },
            success: (response) => {
                form.setFieldsValue(response.data);

                if (marker) {
                    marker.remove();
                }
                let icon = L.icon({
                    iconUrl: ImageLocation,
                    iconSize: [50, 50],
                });
                marker = L.marker([markerY, markerX], {icon: icon}).addTo(map);

                request.current = false;
                setIsLoad(false);
                setIsMarker(true);
                if (response.data.areaId) {
                    setAreaId(response.data.areaId);
                }
                setCoordinateX(coordinateX);
                setCoordinateY(coordinateY);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleSaveCubeArea = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.coordinateX = coordinateX;
                data.coordinateY = coordinateY;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/cube/area/backend/website/v1/save",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);
                        setAreaId(response.data.areaId);
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    const handleUpdateCubeArea = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.areaId = areaId;
                data.coordinateX = coordinateX;
                data.coordinateY = coordinateY;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/cube/area/backend/website/v1/update",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    const handleSubmitCubeArea = () => {
        if (areaId) {
            handleUpdateCubeArea();
        } else {
            handleSaveCubeArea();
        }
    };

    return (
        <React.Fragment>
            <Scrollbars
                autoHeight={autoHeight}
                autoHeightMax={main.height - 300}
                renderView={({style, ...props}) => {
                    return <div className="scrollbar" style={style} {...props} />;
                }}
            >
                <Breadcrumb className="breadcrumb">
                    {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                        return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                    })}
                    {props.breadcrumb ? <Breadcrumb.Item key={props.breadcrumb.name}>{props.breadcrumb.name}</Breadcrumb.Item> : null}
                </Breadcrumb>
                <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                <Form form={form} autoComplete="off" layout="vertical">
                    <div style={{display: "flex", flexDirection: main.width > 600 ? "row" : "column"}}>
                        <div style={main.width > 600 ? {flex: 1, width: 0} : {width: "100%"}}>
                            <Card
                                className="card"
                                style={{marginRight: "0px", paddingTop: "20px", paddingBottom: "10px"}}
                                bodyStyle={{height: main.height - 170 + "px"}}
                                bordered={false}
                            >
                                <Button
                                    type="primary"
                                    icon={<IconSave />}
                                    loading={isLoad}
                                    onClick={() => {
                                        if (request.current) {
                                            return;
                                        }
                                        request.current = true;

                                        Message.loading({
                                            id: "loading",
                                            content: outletContext.intl.formatMessage({id: "global.loading"}),
                                            duration: 0,
                                        });

                                        setIsLoad(true);
                                        setErrorTitle("");
                                        setErrorList([]);

                                        Util.request(outletContext, {
                                            url: process.env.API_URL + "/block/backend/website/v1/clean/cache",
                                            controller: controller,
                                            data: {},
                                            success: (response) => {
                                                Message.success({
                                                    id: "loading",
                                                    content: response.message,
                                                });

                                                request.current = false;
                                                setIsLoad(false);
                                            },
                                            error: (response) => {
                                                Message.error({
                                                    id: "loading",
                                                    content: response.message,
                                                });

                                                setErrorTitle(response.message);
                                                setErrorList(response.error);

                                                request.current = false;
                                                setIsLoad(false);
                                            },
                                            complete: () => {},
                                        });
                                    }}
                                >
                                    清理缓存
                                </Button>
                                <div id="map" style={{width: "100%", height: "100%", backgroundColor: "#000000"}}></div>
                            </Card>
                        </div>
                        <div style={main.width > 600 ? {width: "340px", flexShrink: 0} : {width: "100%"}}>
                            <Card
                                className="card"
                                style={{paddingTop: "20px", paddingBottom: "10px"}}
                                bodyStyle={{height: main.height - 170 + "px"}}
                                bordered={false}
                            >
                                {isMarker ? (
                                    <React.Fragment>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "cubeArea.areaName"})}
                                            field="areaName"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 50,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitCubeArea();
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "cubeArea.areaImage"})}
                                            field="areaImage"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 200,
                                                },
                                            ]}
                                        >
                                            <File type="image" limit={1} />
                                        </Form.Item>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "cubeArea.areaSize"})}
                                            field="areaSize"
                                            initialValue={1}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: "number",
                                                    min: 1,
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitCubeArea();
                                                }}
                                            />
                                        </Form.Item>
                                        {areaId ? (
                                            <Button
                                                type="primary"
                                                icon={<IconSave />}
                                                loading={isLoad}
                                                onClick={() => {
                                                    handleSubmitCubeArea();
                                                }}
                                            >
                                                {outletContext.intl.formatMessage({id: "global.update"})}
                                            </Button>
                                        ) : (
                                            <Button
                                                type="primary"
                                                icon={<IconSave />}
                                                loading={isLoad}
                                                onClick={() => {
                                                    handleSubmitCubeArea();
                                                }}
                                            >
                                                {outletContext.intl.formatMessage({id: "global.save"})}
                                            </Button>
                                        )}
                                    </React.Fragment>
                                ) : null}
                            </Card>
                        </div>
                    </div>
                </Form>
            </Scrollbars>
            {isLoad ? <Loading /> : null}
        </React.Fragment>
    );
};

CommunityIndex.propTypes = {
    autoHeight: PropTypes.bool,
    breadcrumb: PropTypes.object,
    selectType: PropTypes.string,
    onSelect: PropTypes.func,
};

export default Util.withRouter(CommunityIndex);
