import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import {Message, Modal, Breadcrumb, Card, Grid, Form, Space, Button, Input, InputNumber, Select, Switch} from "@arco-design/web-react";
import {IconRefresh, IconClose, IconCloseCircle, IconCheckCircle} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import Loading from "../../../../../nowui-common/component/loading";
import File from "../../../../../nowui-common/component/file";

const AdvertisementDetail = ({...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumb, setBreadcrumb] = useState({});
    const [breadcrumbList, setBreadcrumbList] = useState([]);

    useEffect(() => {
        let breadcrumb = {};
        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/advertisement/index");
        if (props.action == "save") {
            breadcrumb = {
                name: outletContext.intl.formatMessage({id: "global.new"}) + outletContext.intl.formatMessage({id: "advertisement"}).toLowerCase(),
            };
        } else if (props.action == "update") {
            breadcrumb = {
                name: outletContext.intl.formatMessage({id: "global.modify"}) + outletContext.intl.formatMessage({id: "advertisement"}).toLowerCase(),
            };

            handleLoadAdvertisement();

            setIsRefresh(true);
        }
        setBreadcrumb(breadcrumb);
        setBreadcrumbList(breadcrumbList.concat([breadcrumb]));

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        return () => {};
    }, [localStorage.getItem("locale")]);

    const handleLoadAdvertisement = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/advertisement/backend/website/v1/item",
            controller: controller,
            data: {
                advertisementId: props.advertisementId,
            },
            success: (response) => {
                form.setFieldsValue(response.data);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleSubmitAdvertisement = () => {
        if (props.action == "save") {
            handleSaveAdvertisement();
        } else if (props.action == "update") {
            handleUpdateAdvertisement();
        }
    };

    const handleSaveAdvertisement = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                Util.request(outletContext, {
                    url: process.env.API_URL + "/advertisement/backend/website/v1/save",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        props.onSubmit();
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    const handleUpdateAdvertisement = () => {
        if (request.current) {
            return;
        }

        form.validate()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                data.advertisementId = props.advertisementId;

                Util.request(outletContext, {
                    url: process.env.API_URL + "/advertisement/backend/website/v1/update",
                    controller: controller,
                    data: data,
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        props.onSubmit();
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV == "development") {
                    console.log(error);
                }
            });
    };

    return (
        <React.Fragment>
            <Modal
                style={{top: "100px", width: main.width - 300 <= 1024 ? main.width + "px" : main.width - 300 + "px"}}
                title={
                    <React.Fragment>
                        <div className="arco-modal-title-text">{breadcrumb.name}</div>
                        <Space>
                            {main.permissionList.indexOf(Util.md5("/advertisement/backend/website/v1/item")) > -1 && isRefresh ? (
                                <Button
                                    className="arco-modal-title-button"
                                    type="text"
                                    icon={<IconRefresh className="navigation_icon" />}
                                    onClick={() => {
                                        handleLoadAdvertisement();
                                    }}
                                ></Button>
                            ) : null}
                            <Button
                                className="arco-modal-title-button"
                                type="text"
                                icon={<IconClose className="navigation_icon" />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            ></Button>
                        </Space>
                    </React.Fragment>
                }
                visible={props.visible}
                focusLock={false}
                alignCenter={false}
                maskClosable={false}
                closeIcon={false}
                footer={
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1, marginRight: "8px"}}></div>
                        <Space>
                            <Button
                                icon={<IconCloseCircle />}
                                onClick={() => {
                                    props.onClose();
                                }}
                            >
                                {outletContext.intl.formatMessage({id: "global.close"})}
                            </Button>
                            {main.permissionList.indexOf(Util.md5("/advertisement/backend/website/v1/save")) > -1 && props.action === "save" ? (
                                <Button
                                    type="primary"
                                    icon={<IconCheckCircle />}
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSubmitAdvertisement();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.save"})}
                                </Button>
                            ) : null}
                            {main.permissionList.indexOf(Util.md5("/advertisement/backend/website/v1/update")) > -1 && props.action === "update" ? (
                                <Button
                                    type="primary"
                                    icon={<IconCheckCircle />}
                                    loading={isLoad}
                                    onClick={() => {
                                        handleSubmitAdvertisement();
                                    }}
                                >
                                    {outletContext.intl.formatMessage({id: "global.update"})}
                                </Button>
                            ) : null}
                        </Space>
                    </div>
                }
            >
                <Scrollbars
                    autoHeight
                    autoHeightMax={main.height - 300}
                    renderView={({style, ...props}) => {
                        style.overflow = isLoad ? "hidden" : "auto";

                        return <div className="scrollbar" style={style} {...props} />;
                    }}
                >
                    <div style={{width: main.width - 300 <= 1024 ? main.width + "px" : main.width - 300 + "px"}}>
                        <Breadcrumb className="breadcrumb">
                            {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                                return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                            })}
                        </Breadcrumb>
                        <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                        <Form form={form} autoComplete="off" layout="vertical">
                            <Card className="card" title={outletContext.intl.formatMessage({id: "global.basicInformation"})} bordered={false}>
                                <Grid.Row gutter={20}>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "advertisement.advertisementType"})}
                                            field="advertisementType"
                                            rules={[
                                                {
                                                    type: "number",
                                                    min: 0,
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseSelect"})}
                                                allowClear
                                                onChange={() => {}}
                                            >
                                                <Select.Option value={0}>启动页面</Select.Option>
                                                <Select.Option value={1}>电站页面</Select.Option>
                                                <Select.Option value={2}>商城页面</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "advertisement.advertisementImage"})}
                                            field="advertisementImage"
                                            rules={[
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 200,
                                                },
                                            ]}
                                        >
                                            <File type="image" limit={1} />
                                        </Form.Item>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row gutter={20}>
                                    <Grid.Col xs={24} sm={24} md={24} lg={24}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "advertisement.advertisementUrl"})}
                                            field="advertisementUrl"
                                            rules={[
                                                {
                                                    type: "string",
                                                    minLength: 0,
                                                    maxLength: 300,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitAdvertisement();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "advertisement.advertisementSort"})}
                                            field="advertisementSort"
                                            initialValue={0}
                                            rules={[
                                                {
                                                    type: "number",
                                                    min: 0,
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                                allowClear
                                                onPressEnter={() => {
                                                    handleSubmitAdvertisement();
                                                }}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label={outletContext.intl.formatMessage({id: "advertisement.advertisementStatus"})}
                                            field="advertisementStatus"
                                            initialValue={true}
                                            rules={[
                                                {
                                                    type: "number",
                                                    min: 0,
                                                },
                                            ]}
                                            triggerPropName="checked"
                                        >
                                            <Switch
                                                checkedText={outletContext.intl.formatMessage({id: "global.on"})}
                                                uncheckedText={outletContext.intl.formatMessage({id: "global.off"})}
                                            />
                                        </Form.Item>
                                    </Grid.Col>
                                </Grid.Row>
                            </Card>
                        </Form>
                        <div className="card_bottom"></div>
                    </div>
                </Scrollbars>
                {isLoad ? <Loading /> : null}
            </Modal>
        </React.Fragment>
    );
};

AdvertisementDetail.propTypes = {
    visible: PropTypes.bool.isRequired,
    action: PropTypes.string.isRequired,
    advertisementId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default Util.withRouter(AdvertisementDetail);
